
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class Tenby02 extends Vue {
        // Fields
        private readonly drones = {
            // tslint:disable
            mainstream: [
                {
                    image:      require("@/assets/images/drone_dji_mavic_mini_2.png"),
                    name:       'DJI Mavic Mini 2',
                    price:      419,
                    weight:     249,
                    duration:   30,
                    resolution: '3,840 × 2,160 @ 30fps<br>1,920 x 1,080 @ 60fps',
                    hdrIcon:    'not_interested',
                },
                {
                    image:      require("@/assets/images/drone_parrot_anafi.png"),
                    name:       'Parrot Anafi',
                    price:      550,
                    weight:     320,
                    duration:   25,
                    resolution: '4,096 × 2,160 @ 24fps<br>3,840 × 2,160 @ 30fps<br>1,920 × 1,080 @ 60fps',
                    hdrIcon:    'check',
                },
                {
                    image:      require("@/assets/images/drone_dji_mavic_air_2.png"),
                    name:       'DJI Mavic Air 2',
                    price:      769,
                    weight:     570,
                    duration:   34,
                    resolution: '3,840 × 2,160 @ 60fps<br>3,840 × 2,160 HDR @ 30fps<br>1,920 x 1,080 @ 120fps',
                    hdrIcon:    'check',
                },
                {
                    image:      require("@/assets/images/drone_dji_mavic_pro_2.png"),
                    name:       'DJI Mavic 2 Pro',
                    price:      1180,
                    weight:     907,
                    duration:   31,
                    resolution: '3,840 × 2,160 @ 30fps<br>3,840 × 2,160 HDR @ 30fps<br>1,920 x 1,080 @ 120fps',
                    hdrIcon:    'check',
                }
            ],

            headers: [
                { text: '',                 value: 'image', sortable: false, },
                {
                    text:       'Name',
                    align:      'left',
                    sortable:   false,
                    value:      'name',
                },
                { text: 'Price',            value: 'price' },
                { text: 'Weight',           value: 'weight' },
                { text: 'Flight Time',      value: 'duration' },
                { text: 'Resolution',       value: 'resolution' },
                { text: 'HDR ?',            value: 'hdrIcon' },
            ],
            // tslint:enable
        };

        // Data function
        private data() {
            return {
                droneIconPathAndName:
                    require("@/assets/images/icon_drone.png"),
            };
        }
    }
